<template>
  <div>
    <page-toolbar title="Set PIC"></page-toolbar>

    <v-container>
      <page-title :title="cpar_header.document_no_">
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col cols="12">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="12">
                <v-row class="mb-3">
                  <v-col cols="12" md="6">
                    <v-text-field filled label="Document No." v-model="cpar_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Document Date" v-model="cpar_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Area Code" v-model="cpar_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Document Type" v-model="cpar_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <span class="text-md-body-1 mb-2 d-block">Ditujukan kepada: </span>
                    <v-sheet elevation="1" outlined>
                      <v-simple-table dense outlined>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Subject</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(sip, i) in cpar_subject" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ sip.description }} / {{ sip.code }}<br>{{ sip.source }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-sheet>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled label="Created By" v-model="cpar_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Created Date" v-model="cpar_header.created_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Status" v-model="cpar_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field filled label="Sub Status" v-model="cpar_header.substatus" hide-details outlined dense class="mb-2" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-btn @click="redirect('Administration.CPAR.Detail', { id: cpar_header.id })" text outlined>View Detail</v-btn>
              </v-col>
            </v-row>
          </card-expansion>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <set-pic-analysis :cpar_header="cpar_header" ref="refSetPicAnalysis"></set-pic-analysis>
        </v-col>
        <v-col cols="12" md="4">
          <set-pic-correction :cpar_header="cpar_header" ref="refSetPicCorrection"></set-pic-correction>
        </v-col>
        <v-col cols="12" md="4">
          <set-pic-corrective :cpar_header="cpar_header" ref="refSetPicCorrection"></set-pic-corrective>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SetPicAnalysis from "./components/SetPicAnalysis.vue";
import SetPicCorrection from "./components/SetPicCorrection.vue";
import SetPicCorrective from "./components/SetPicCorrective.vue";

export default {
  components: {
    SetPicAnalysis,
    SetPicCorrection,
    SetPicCorrective,
  },
  data() {
    return {
      id: null,
      loading: false,
      cpar_header: {},
      cpar_subject: [],
    };
  },
  computed: {
    showSubmitBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;
      return false;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },
    async getDetail(refresh = false) {
      this.loading = true;

      this.cpar_header = {};
      this.cpar_approval = [];
      this.cpar_comment = [];
      this.user = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cpar/detail", config)
        .then((res) => {
          this.cpar_header = res.data.data.cpar_header;
          this.cpar_approval = res.data.data.cpar_approval;
          this.cpar_comment = res.data.data.cpar_comment;
          this.cpar_subject = res.data.data.cpar_subject;
          this.user = res.data.data.user;

          // this.$refs.refSetPicAnalysis.getData(true);

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.id = parseInt(this.$route.query.id);

    this.getDetail(true);
  },
};
</script>
