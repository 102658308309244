<template>
  <div>
    <card-expansion :isMinimize="false" title="PIC - Root Cause Analysis" class="mb-3" no-padding>
      <div v-if="loading">
        <center><v-progress-circular indeterminate color="primary"></v-progress-circular></center>
      </div>
      <v-list tile class="mx-auto" v-if="!loading" dense>
        <v-list-item v-for="(pic, i) in pics" :key="i" two-line style="border-bottom: #ddd solid 1px">
          <v-list-item-avatar>
            <v-img :src="pic.user_img"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <UserInfo :username="pic.username">{{ pic.username }}</UserInfo>
            </v-list-item-title>
            <v-list-item-subtitle>{{ pic.employee_position }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon>
              <v-icon color="error lighten-1" @click="deleteData(pic.id)">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <template slot="action">
        <v-btn icon text small color="primary" @click="refreshData">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <SetPicNew :proc="proc" :cpar_header="cpar_header" @callback="refreshData"></SetPicNew>
      </template>
    </card-expansion>
  </div>
</template>

<script>
import SetPicNew from "./SetPICNew.vue";
import UserInfo from "@/components/user/UserInfo.vue";
export default {
  components: {
    SetPicNew,
    UserInfo,
  },
  props: {
    cpar_header: Object,
  },

  data: () => {
    return {
      loading: false,
      pics: false,
      proc: "update-root-cause-analysis",
    };
  },

  watch: {
    cpar_header: {
      // eslint-disable-next-line no-unused-vars
      handler(n, o) {
        if (typeof n.id !== "undefined") {
          if (typeof this.pics[0] === "undefined") this.getData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    refreshData() {
      this.getData(true);
    },
    async getData(refresh = false) {
      this.loading = true;
      this.pics = {};
      if (refresh == true) this.AxiosStorageRemove("GET", "cparpic/data");
      var config = {
        params: {
          cpar_header_id: this.cpar_header.id,
          proc: this.proc,
        },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("cparpic/data", config)
        .then((res) => {
          this.pics = res.data.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    deleteData(id) {
      const post = async () => {
        this.loading = true;
        this.pics = {};
        var config = {
          params: {
            id: id,
          },
        };
        await this.$axios
          .delete("cparpic/delete", config)
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.showAlert(res.data.status, res.data.message);
            this.refreshData();
          })
          .catch((error) => {
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Delete PIC?", post);
    },
  },

  updated() {},
};
</script>
